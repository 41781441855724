







import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import productComboStatuses from "@/constants/productComboStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";
import productComboProductItemRoles from "@/constants/productComboProductItemRoles";
import { displayItemsWithSku } from "@/utils/marketItemDisplay";

export default Vue.extend({
  props: ["item", "mktPlatformIds", "markets"],
  data() {
    return {
      self: this,
      country: null,
      tableOptions: null,
      tableContainer: new DataContainer(),
    };
  },
  watch: {
    mktPlatformIds: {
      async handler() {
        const dataTable = this.tableContainer && this.tableContainer.value;
        if (dataTable) {
          dataTable.refresh();
        }
      },
      deep: true,
    },
  },
  methods: {
    makeTableOptions(country) {
      const self = this;
      const options = {
        id: "productCombosTable",
        attrs: {
          "item-key": "_id",
          // "sort-by": "createdTime",
          // "sort-desc": true,
          //   "show-select": true,
          "hide-default-footer": true,
          "items-per-page": -1,
          "footer-props": {
            "items-per-page-options": [-1],
          },
          "fixed-header": false,
        },
        content: {
          urlEnabled: false,
          mainCard: {
            attrs: {
              // flat: true,
              // tile: true,
              // dark: true,
            },
          },
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            stt: {
              text: "STT",
              options: {
                index: true,
              },
            },
            id: {
              text: "ID",
              hidden: true,
            },
            market: {
              text: "Thị trường",
              options: {
                subProp: "market.name",
                label: true,
                sortBy: "marketId",
              },
            },
            name: {
              text: "Combo",
              sortable: true,
            },
            altNames: {
              text: "SKU TMĐT",
              hidden: true,
              options: {
                labels: true,
                labelItems(value) {
                  return value;
                },
              },
            },
            mktName: {
              text: "Tên hiển thị",
              sortable: true,
              hidden: true,
            },
            stickerName: {
              text: "Tên in trên sticker",
              sortable: true,
              hidden: true,
            },
            mktPlatforms: {
              text: "Nền tảng MKT",
              options: {
                labels: true,
                labelItems(value) {
                  return value.name;
                },
              },
            },
            localPrice: {
              text: "Giá bán nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            optimizedLocalPrice: {
              text: "Giá bán nội địa MKT tối ưu",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            localShippingCost: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Chi phí ship nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            realLocalShippingCost: {
              text: "Chi phí ship nội địa thực",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            expectedRevenue: {
              text: "Doanh thu MKT",
              options: {
                preTransform(_, item) {
                  let value = (item.localPrice - item.localShippingCost) * country.exchangeRate;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
              hidden: true,
            },
            realExpectedRevenue: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Doanh thu MKT thực",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.localPrice - (item.realLocalShippingCost || item.localShippingCost || 0)) *
                    country.exchangeRate;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
              hidden: true,
            },
            status: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return productComboStatuses[value || ""];
                },
                attrs(value) {
                  const attrs: any = {};
                  if (value === "nb") {
                    attrs.color = "red";
                  }
                  return attrs;
                },
              },
            },
            statusNbStartTime: {
              text: "Thời gian ngừng bán",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            description: {
              text: "Mô tả",
              hidden: true,
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
              hidden: true,
            },
            action: {},
          },
          topActionButtons: {
            showDisplaySetting: {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                text: "",
              },
            },
            import: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                text: "",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel - Combo",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                  marketId: {
                                    type: "XAutocomplete",
                                    attrs: {
                                      label: "Thị trường",
                                      required: true,
                                      "item-value": "_id",
                                      "item-text": "name",
                                      xOptions: {
                                        content: {
                                          async itemsLoader() {
                                            const { items } = await coreApiClient.call("markets", "findAll", {
                                              limit: -1,
                                              filters: [
                                                {
                                                  key: "countryId",
                                                  operator: "equal_to",
                                                  value: country._id,
                                                },
                                              ],
                                            });
                                            return items;
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productCombos",
                                  "import",
                                  {
                                    marketId: data.marketId,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("productCombos", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                text: "",
              },
              target: {
                dialog: {
                  attrs: {
                    width: "1000px",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "1000px",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["kt"]) && {},
              updatePrice: userManager.checkRole(["kt", "vdl", "vd"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.sku || item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            newLocalPrice: item.localPrice,
                            newLocalShippingCost: item.localShippingCost,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    newLocalPrice: {
                                      type: "number",
                                      attrs: {
                                        label: "Giá bán nội địa",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                        autofocus: true,
                                      },
                                    },
                                    newLocalShippingCost: {
                                      type: "number",
                                      attrs: {
                                        label: "Chi phí ship nội địa",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "productCombos",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        localPrice: data.newLocalPrice,
                                        localShippingCost: data.newLocalShippingCost,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "productItems.belongsToProductGroupId",
              operator: "equal_to",
              value: self.item._id,
            });
            if (self.mktPlatformIds && self.mktPlatformIds.length) {
              options.filters.push({
                key: "mktPlatformIds",
                operator: "in",
                value: self.mktPlatformIds,
              });
            }
            if (self.markets) {
              options.filters.push({
                key: "marketId",
                operator: "in",
                value: self.markets.map((item) => item._id),
              });
            }
            options.orderItems = [];
            if (options.orderBy) {
              options.orderItems.push({
                field: options.orderBy,
                type: options.orderType,
              });
            }
            options.orderItems.push({
              field: "status",
              type: "asc",
            });
            options.orderItems.push({
              field: "createdTime",
              type: "desc",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("productCombos", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.countryId = dataTable.context().$route.params.countryId;
              return await coreApiClient.call("productCombos", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "productCombos",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productCombos", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              altNames: {
                type: "textMultiple",
                attrs: {
                  label: "SKU TMĐT",
                },
              },
              mktName: {
                attrs: {
                  label: "Tên hiển thị",
                },
                ext: {
                  colLength: 6,
                },
              },
              stickerName: {
                attrs: {
                  label: "Tên in trên sticker",
                },
                ext: {
                  colLength: 6,
                },
              },
              marketId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("markets", "findAll", {
                          limit: -1,
                          filters: [
                            {
                              key: "countryId",
                              operator: "equal_to",
                              value: country._id,
                            },
                          ],
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(productComboStatuses),
                },
                ext: {
                  defaultValue: "db",
                },
              },
              statusNbStartTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Thời gian ngừng bán",
                  xOptions: {
                    content: {
                      resetable: true,
                    },
                  },
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              localPrice: {
                type: "number",
                attrs: {
                  label: "Giá bán nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              optimizedLocalPrice: {
                type: "number",
                attrs: {
                  label: "Giá bán nội địa MKT tối ưu",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              localShippingCost: {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              realLocalShippingCost: {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa thực",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
                ext: {
                  colLength: 6,
                },
              },
              mktPlatformIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mainProductGroupIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm chính",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return displayItemsWithSku(items);
                      },
                    },
                  },
                },
              },
              productItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Sản phẩm / Nhóm sản phẩm",
                  xOptions: {
                    content: {
                      itemLabel: (item, i) =>
                        (item.product && item.product.sku) ||
                        (item.productGroup && item.productGroup.sku) ||
                        `Sản phẩm / Nhóm sản phẩm ${i + 1}`,
                      itemProperties: {
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                        product: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: country._id,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                          ext: {
                            onInput(value, form) {
                              form.decouratedData.productId = value ? value._id : "";
                            },
                          },
                        },
                        productGroup: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Nhóm sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("productGroups", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: country._id,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                          ext: {
                            onInput(value, form) {
                              form.decouratedData.productGroupId = value ? value._id : "";
                            },
                          },
                        },
                        role: {
                          type: "select",
                          attrs: {
                            label: "Vai trò",
                            items: makeSelectOptions(productComboProductItemRoles),
                            required: true,
                          },
                          ext: {
                            defaultValue: "main",
                          },
                        },
                        productStickerName: {
                          attrs: {
                            label: "Tên in trên sticker",
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 2,
                            rules: {
                              product(data) {
                                if (!data.product && !data.productGroup) {
                                  return false;
                                } else if (data.product && data.productGroup) {
                                  return false;
                                }
                                return true;
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "name",
                  "altNames",
                  "mktName",
                  "stickerName",
                  "marketId",
                  "status",
                  "statusNbStartTime",
                  "description",
                  "localPrice",
                  "optimizedLocalPrice",
                  "localShippingCost",
                  "realLocalShippingCost",
                  "mktPlatformIds",
                  "mainProductGroupIds",
                  "productItems",
                ],
              },
            },
            editForm: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "name",
                  "altNames",
                  "mktName",
                  "stickerName",
                  "marketId",
                  "status",
                  "statusNbStartTime",
                  "description",
                  "localPrice",
                  "optimizedLocalPrice",
                  "localShippingCost",
                  "realLocalShippingCost",
                  "mktPlatformIds",
                  "mainProductGroupIds",
                  "productItems",
                ],
              },
            },
            delete: userManager.checkRole(["kt"]) && {
              confirmDisplayField: "sku",
            },
          },
        },
      };
      return options;
    },
  },
  async created() {
    this.country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(this.country);
  },
});
