






























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import productGroupStatuses from "@/constants/productGroupStatuses";
import productGroupTypes from "@/constants/productGroupTypes";
import productGroupRdTestStatuses from "@/constants/productGroupRdTestStatuses";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import ProductsTable from "./product-groups/partials/ProductsTable.vue";
import ProductCombosTable from "./product-groups/partials/ProductCombosTable.vue";
import productGroupGiftTypes from "@/constants/productGroupGiftTypes";

export default Vue.extend({
  components: { ProductsTable, ProductCombosTable },
  data() {
    return {
      self: this,
      userManager,
      country: null,
      tableOptions: null,
      tableContainer: new DataContainer(),
      mktPlatformIds: [],
      mainOrRdMarkets: [],
      mktPlatformsPickerOptions: {
        attrs: {
          label: "Nền tảng MKT",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
    };
  },
  watch: {
    mktPlatformIds: {
      handler() {
        this.tableContainer.value.refresh();
      },
      deep: true,
    },
  },
  methods: {
    makeTableOptions(country) {
      const self = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "status",
          "sort-desc": false,
          "show-expand": true,
        },
        content: {
          name: "Sản phẩm đang bán",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              _id: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in_id: {},
                },
              },
              productCategoryId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phân loại SP",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCategories", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Loại hàng",
                  multiple: true,
                  items: makeSelectOptions(productGroupTypes),
                },
                rules: {
                  in: {},
                },
              },
              giftType: {
                type: "select",
                attrs: {
                  label: "Phân loại",
                  multiple: true,
                  items: makeSelectOptions(productGroupGiftTypes),
                },
                rules: {
                  in: {},
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  multiple: true,
                  items: makeSelectOptions(productGroupStatuses),
                },
                rules: {
                  in: {},
                },
              },
              launchTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày triển khai (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày triển khai (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              rdTestStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái test RD",
                  items: makeSelectOptions(productGroupRdTestStatuses),
                },
                rules: {
                  in: {},
                },
              },
              rdUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "RD",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            stt: {
              text: "STT",
              options: {
                index: true,
              },
            },
            sku: {
              text: "SKU",
              sortable: true,
              width: "150px",
            },
            name: {
              text: "Tên",
              sortable: true,
            },
            productCategory: {
              text: "Phân loại SP",
              options: {
                label: true,
                subProp: "productCategory.name",
              },
            },
            type: {
              text: "Loại hàng",
              options: {
                label(value) {
                  return productGroupTypes[value || ""];
                },
              },
            },
            giftType: {
              text: "Phân loại",
              options: {
                label(value) {
                  return productGroupGiftTypes[value || ""];
                },
              },
            },
            rdTestStatus: {
              text: "Trạng thái test RD",
              sortable: true,
              options: {
                label(value) {
                  return productGroupRdTestStatuses[value];
                },
              },
            },
            rdTestSuccessTime: {
              text: "Ngày test thành công",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            rdUser: {
              text: "RD",
              options: {
                subProp: "rdUser.name",
                label: true,
                sortBy: "rdUser",
              },
            },
            teams: {
              text: "Team MKT chạy",
              options: {
                labels(items) {
                  return items && items.map((item) => item.name);
                },
              },
            },
            departments: {
              text: "Phòng",
              options: {
                labels(items) {
                  return items && items.map((item) => item.name);
                },
              },
            },
            status: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return productGroupStatuses[value || ""];
                },
                attrs(value) {
                  const attrs: any = {};
                  if (value === "nb") {
                    attrs.color = "red";
                  }
                  return attrs;
                },
              },
            },
            localPrice: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              text: "Giá bán nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
              hidden: true,
            },
            localShippingCost: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Chi phí ship nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
              hidden: true,
            },
            expectedRevenue: userManager.checkRole(["kt", "mkt", "tp", "rd", "rdl"]) && {
              text: "Doanh thu MKT",
              options: {
                preTransform(_, item) {
                  let value = (item.localPrice - item.localShippingCost) * country.exchangeRate;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
              hidden: true,
            },
            description: {
              text: "Mô tả",
            },
            launchTime: {
              text: "Ngày triển khai",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            sampleUrl: {
              text: "Link minh họa",
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
              hidden: true,
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel - Nhóm sản phẩm",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productGroups",
                                  "import",
                                  {
                                    countryId: country._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk", "vd", "vdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("productGroups", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt", "qlk"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "700px",
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "700px",
                    },
                    ext: {
                      subTitleMaker: "sku",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["kt"]) && {},
              updatePrice: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.sku || item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            newLocalPrice: item.localPrice,
                            newLocalShippingCost: item.localShippingCost,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    newLocalPrice: {
                                      type: "number",
                                      attrs: {
                                        label: "Giá bán nội địa",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                        autofocus: true,
                                      },
                                    },
                                    newLocalShippingCost: {
                                      type: "number",
                                      attrs: {
                                        label: "Chi phí ship nội địa",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "productGroups",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        localPrice: data.newLocalPrice,
                                        localShippingCost: data.newLocalShippingCost,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: this.context().$route.params.countryId,
            });
            options.orderItems = [];
            if (options.orderBy) {
              options.orderItems.push({
                field: options.orderBy,
                type: options.orderType,
              });
              options.orderBy = undefined;
              options.orderType = undefined;
            }
            options.orderItems.push({
              field: "status",
              type: "asc",
            });
            options.orderItems.push({
              field: "createdTime",
              type: "desc",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const mktPlatformIds = [...self.mktPlatformIds];
              options.extraParams = {
                mktPlatformIds,
              };
              const { payload: _payload, ...payloadData } = options;
              options.payload = JSON.stringify(payloadData);
              const { items, count } = await coreApiClient.call("productGroups", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.countryId = dataTable.context().$route.params.countryId;
              return await coreApiClient.call("productGroups", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "productGroups",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productGroups", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              sku: {
                attrs: {
                  label: "SKU",
                  required: true,
                },
              },
              productCategoryId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phân loại SP",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCategories", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              type: {
                type: "select",
                attrs: {
                  label: "Loại hàng",
                  items: makeSelectOptions(productGroupTypes),
                },
                ext: {
                  defaultValue: "normal",
                },
              },
              giftType: {
                type: "select",
                attrs: {
                  label: "Phân loại",
                  items: makeSelectOptions(productGroupGiftTypes),
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(productGroupStatuses),
                },
                ext: {
                  defaultValue: "db",
                },
              },
              rdTestStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái test RD",
                  items: makeSelectOptions(productGroupRdTestStatuses),
                },
                ext: {
                  defaultValue: "",
                },
              },
              rdTestSuccessTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày test thành công",
                  xOptions: {
                    content: {
                      setDefaultValue: false,
                      resetable: true,
                    },
                  },
                },
              },
              rdUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "RD",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              teamIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team MKT chạy",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              departmentIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              localPrice: {
                type: "number",
                attrs: {
                  label: "Giá bán nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
              },
              localShippingCost: {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
              },
              launchTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày triển khai",
                },
              },
              sampleUrl: {
                attrs: {
                  label: "Link minh họa",
                },
              },
              properties: {
                type: "XArrayInput",
                attrs: {
                  label: "Thuộc tính",
                  xOptions: {
                    content: {
                      itemLabel: (item) => item.label || item.key || "[Trống]",
                      itemProperties: {
                        key: {
                          attrs: {
                            required: true,
                            label: "Mã",
                            autofocus: true,
                          },
                        },
                        label: {
                          attrs: {
                            label: "Hiển thị",
                          },
                        },
                        type: {
                          type: "select",
                          attrs: {
                            required: true,
                            label: "Loại",
                            items: [
                              { value: "select", text: "Lựa chọn" },
                              { value: "text", text: "Text" },
                            ],
                          },
                          ext: {
                            defaultValue: "select",
                          },
                        },
                        selections: {
                          type: "comboBoxMultiple",
                          attrs: {
                            label: "Lựa chọn",
                          },
                          ext: {
                            condition(self) {
                              return self.inputs.type.value === "select";
                            },
                          },
                        },
                        skus: {
                          type: "comboBoxMultiple",
                          attrs: {
                            label: "Lựa chọn (SKU)",
                          },
                          ext: {
                            condition(self) {
                              return self.inputs.type.value === "select";
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "qlk"]) && {
              content: {
                fieldNames: ["sku", "name", "productCategoryId", "type", "giftType", "status", "rdTestStatus", "rdTestSuccessTime", "rdUserId", "teamIds", "departmentIds", "description", "launchTime", "sampleUrl", "localPrice", "localShippingCost", "properties"],
              },
            },
            editForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: ["sku", "name", "productCategoryId", "type", "giftType", "status", "rdTestStatus", "rdTestSuccessTime", "rdUserId", "teamIds", "departmentIds", "description", "launchTime", "sampleUrl", "localPrice", "localShippingCost", "properties"],
              },
            },
            delete: userManager.checkRole(["kt"]) && {
              confirmDisplayField: "name",
            },
          },
          delete: {},
        },
      };
    },
    expandItem() {
      // info.expand(!info.isExpanded);
    },
  },
  async created() {
    this.country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    {
      const { items } = await coreApiClient.call("markets", "findAll", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            {
              key: "countryId",
              operator: "equal_to",
              value: this.country._id,
            },
          ],
        }),
      });
      this.mainOrRdMarkets = items.filter((item) => !item.code.includes("telesale"));
    }
    this.tableOptions = this.makeTableOptions(this.country);
  },
});
