
























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import productUnits from "@/constants/productUnits";
import makeSelectOptions from "@common/util/makeSelectOptions";
import moment from "moment";

function temporaryBaseCostItemsInput() {
  return {
    type: "XArrayInput",
    attrs: {
      label: "Base cost tạm thời theo thời gian",
      xOptions: {
        content: {
          itemLabel: (item) =>
            `${moment(item.startTime).format("d-m-Y")} - ${moment(
              item.endTime
            ).format("d-m-Y")}: ${
              item.value && Vue.filter("currency")(item.value)
            }`,
          itemProperties: {
            startTime: {
              type: "XDateTimePicker",
              attrs: {
                label: "Thời gian bắt đầu",
              },
            },
            endTime: {
              type: "XDateTimePicker",
              attrs: {
                label: "Thời gian kết thúc",
              },
            },
            value: {
              type: "integer",
              attrs: {
                label: "Base cost",
              },
            },
          },
          template: {
            formOptions: {
              content: {
                colLength: 4,
              },
            },
          },
        },
      },
    },
  };
}

export default Vue.extend({
  props: ["item"],
  data() {
    return {
      self: this,
      country: null,
      tableOptions: null,
    };
  },
  methods: {
    labelValues(item) {
      const value = item.propertyValues;
      if (!value) {
        return [];
      }
      const labelValues = [];
      for (const k in value) {
        const v = value[k];
        const labelValue: any = {
          key: k,
          value: v,
        };

        if (item.productGroup && item.productGroup.properties) {
          labelValue.property = item.productGroup.properties.find(
            (p) => p.key === k
          );
        }
        labelValues.push(labelValue);
      }
      return labelValues;
    },
    makeTableOptions(country) {
      const self = this;
      const self2 = this;
      const options = {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          //   "show-select": true,
          "hide-default-footer": true,
          "items-per-page": -1,
          "footer-props": {
            "items-per-page-options": [-1],
          },
          "fixed-header": false,
        },
        content: {
          id: "productsTable",
          urlEnabled: false,
          mainCard: {
            attrs: {
              // flat: true,
              // tile: true,
              // dark: true,
            },
          },
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            stt: {
              text: "STT",
              options: {
                index: true,
              },
            },
            sku: {
              text: "SKU",
              sortable: true,
              width: "200px",
            },
            altSkus: {
              text: "SKU TMĐT",
              width: "200px",
              hidden: true,
              options: {
                labels: true,
                labelItems(value) {
                  return value;
                },
              },
            },
            name: {
              text: "Sản phẩm",
              sortable: true,
            },
            propertyValues: {
              text: "Thuộc tính",
              width: "200px",
              hidden: true,
            },
            unit: {
              text: "Đơn vị tính",
              sortable: true,
              options: {
                label(value) {
                  return productUnits[value];
                },
              },
            },
            isBag: {
              text: "BAG",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            purchaseAlertDays: userManager.checkRole([
              "kt",
              "qlk",
              "vdl",
              "vd",
            ]) && {
              text: "Số ngày cảnh báo nhập hàng",
              sortable: true,
            },
            purchaseEstimatedDays: userManager.checkRole([
              "kt",
              "qlk",
              "vdl",
              "vd",
            ]) && {
              text: "Số ngày dự kiến đủ hàng",
              sortable: true,
              options: {
                transform(value) {
                  if (value < 0) {
                    return "";
                  }
                  return value;
                },
              },
            },
            priceInherited: userManager.checkRole([
              "kt",
              "qlk",
              "vdl",
              "vd",
            ]) && {
              text: "Lấy giá từ NSP",
              options: {
                boolean: true,
              },
              hidden: true,
            },
            count: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              text: "SL thực trong kho",
              options: {
                subProp: "totalCountStatus.count",
                sortBy: "totalCountStatus.count",
              },
              hidden: true,
            },
            expectedCount: userManager.checkRole([
              "kt",
              "qlk",
              "vdl",
              "vd",
            ]) && {
              text: "SL trong kho (Dự kiến)",
              options: {
                subProp: "totalCountStatus.expectedCount",
                sortBy: "totalCountStatus.expectedCount",
              },
              hidden: true,
            },
            expectedImportCount: userManager.checkRole([
              "kt",
              "qlk",
              "vdl",
              "vd",
            ]) && {
              text: "SL dự kiến về",
              options: {
                subProp: "totalCountStatus.expectedImportCount",
                sortBy: "totalCountStatus.expectedImportCount",
              },
              hidden: true,
            },
            mktCount: {
              text: "SL Marketing",
              options: {
                transform(_, item) {
                  return (
                    item.overriddenMktCount ||
                    (item.totalCountStatus &&
                      (item.totalCountStatus.expectedCount || 0) +
                        (item.totalCountStatus.expectedImportCount || 0))
                  );
                },
              },
            },
            returnCount: userManager.checkRole(["kt"]) && {
              text: "SL return",
              options: {
                subProp: "totalCountStatus.returnCount",
                sortBy: "totalCountStatus.returnCount",
              },
              hidden: true,
            },
            localPrice: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
            ]) && {
              text: "Giá bán nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            localShippingCost: userManager.checkRole(["kt", "vdl", "vd"]) && {
              text: "Chi phí ship nội địa",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            realLocalShippingCost: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
            ]) && {
              text: "Chi phí ship nội địa thực",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${country.currency}`;
                },
              },
            },
            expectedRevenue: userManager.checkRole([
              "kt",
              "mkt",
              "tp",
              "rd",
              "rdl",
            ]) && {
              text: "Doanh thu MKT",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.localPrice - item.localShippingCost) *
                    country.exchangeRate;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
              hidden: true,
            },
            description: {
              text: "Mô tả",
              hidden: true,
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
              hidden: true,
            },
            action: {},
          },
          topActionButtons: {
            showDisplaySetting: {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                text: "",
              },
            },
            import: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                text: "",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel - Sản phẩm",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "products",
                                  "import",
                                  {
                                    countryId: country._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map(
                              (item) => item._id
                            ),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download(
                    "products",
                    `@/export`,
                    findAllOptions
                  );
                },
              },
            },
            insert: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                text: "",
              },
            },
            generateProducts: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                icon: true,
                color: "default",
                dark: false,
              },
              content: {
                icon: "fas fa-cubes",
              },
              target: {
                tooltip: {
                  content: {
                    text: "Tạo sản phẩm tự động",
                  },
                },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled =
                        new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Tạo sản phẩm tự động",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        const skuTemplateItems = [];
                        for (const { key } of self.item.properties) {
                          skuTemplateItems.push(`{${key}}`);
                        }
                        const skuTemplate = skuTemplateItems.length
                          ? `${self.item.sku}-${skuTemplateItems.join("-")}`
                          : self.item.sku;

                        attrs.xModel =
                          dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({
                          priceInherited: true,
                          skuTemplate,
                        });
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  skuTemplate: {
                                    attrs: {
                                      label: "Mẫu SKU",
                                    },
                                  },
                                  priceInherited: {
                                    type: "boolean",
                                    attrs: {
                                      label: "Lấy giá từ nhóm sản phẩm",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Cập nhật",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productGroups",
                                  "createProducts",
                                  {
                                    id: self2.item._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "sku",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["kt"]) && {},
              updatePrice: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled =
                          new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.sku || item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel =
                            dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            newLocalPrice: item.localPrice,
                            newLocalShippingCost: item.localShippingCost,
                          });
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    newLocalPrice: {
                                      type: "number",
                                      attrs: {
                                        label: "Giá bán nội địa mới",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                        autofocus: true,
                                      },
                                    },
                                    newLocalShippingCost: {
                                      type: "number",
                                      attrs: {
                                        label: "Chi phí ship nội địa mới",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: country.currency,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "products",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        localPrice: data.newLocalPrice,
                                        localShippingCost:
                                          data.newLocalShippingCost,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: this.context().$route.params.countryId,
            });
            options.filters.push({
              key: "productGroupId",
              operator: "equal_to",
              value: self.item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "products",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item, dataTable) {
              item.countryId = dataTable.context().$route.params.countryId;
              item.productGroupId = self.item._id;
              return await coreApiClient.call(
                "products",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "products",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("products", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              sku: {
                attrs: {
                  label: "SKU",
                  required: true,
                },
              },
              altSkus: {
                type: "textMultiple",
                attrs: {
                  label: "SKU TMĐT",
                },
              },
              name: {
                attrs: {
                  label: "Tên",
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              unit: {
                type: "select",
                attrs: {
                  label: "Đơn vị tính",
                  items: makeSelectOptions(productUnits),
                },
                ext: {
                  defaultValue: "pcs",
                },
              },
              isBag: {
                type: "boolean",
                attrs: {
                  label: "BAG",
                },
              },
              priceInherited: {
                type: "boolean",
                attrs: {
                  label: "Lấy giá từ NSP",
                },
              },
              overriddenMktCount: {
                type: "number",
                attrs: {
                  label: "Số lượng MKT (ghi đè)",
                },
              },
              localPrice: {
                type: "number",
                attrs: {
                  label: "Giá bán nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
              },
              localShippingCost: userManager.checkRole(["kt", "vdl", "vd"]) && {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
              },
              realLocalShippingCost: {
                type: "number",
                attrs: {
                  label: "Chi phí ship nội địa thực",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: country.currency,
                },
              },
              purchaseAlertDays: {
                type: "integer",
                attrs: {
                  label: "Số ngày cảnh báo nhập hàng",
                },
              },
              temporaryBaseCostItems: temporaryBaseCostItemsInput(),
              temporaryBaseCost: {
                type: "integer",
                attrs: {
                  label: "Base cost tạm thời",
                },
              },
              basePriceItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Giá cơ sở",
                  xOptions: {
                    content: {
                      itemProperties: {
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày bắt đầu",
                            required: true,
                          },
                        },
                        value: {
                          type: "number",
                          attrs: {
                            label: "Giá cơ sở",
                            required: true,
                          },
                        },
                        mktPlatformId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Nền tảng MKT",
                            "item-value": "_id",
                            "item-text": "name",
                            required: true,
                            xOptions: {
                              content: {
                                async itemsLoader() {
                                  const { items } = await coreApiClient.call(
                                    "mktPlatforms",
                                    "findAll",
                                    {
                                      payload: JSON.stringify({
                                        limit: -1,
                                      }),
                                    }
                                  );
                                  return items;
                                },
                              },
                            },
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "qlk"]) && {
              content: {
                fieldNames: [
                  "sku",
                  "altSkus",
                  "name",
                  "description",
                  "unit",
                  "isBag",
                  "overriddenMktCount",
                  "priceInherited",
                  "localPrice",
                  "localShippingCost",
                  "realLocalShippingCost",
                  "purchaseAlertDays",
                  "temporaryBaseCostItems",
                  "temporaryBaseCost",
                  "basePriceItems",
                ],
              },
            },
            editForm: userManager.checkRole(["kt", "qlk", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "sku",
                  "altSkus",
                  "name",
                  "description",
                  "unit",
                  "isBag",
                  "overriddenMktCount",
                  "priceInherited",
                  "localPrice",
                  "localShippingCost",
                  "realLocalShippingCost",
                  "purchaseAlertDays",
                  "temporaryBaseCostItems",
                  "temporaryBaseCost",
                  "basePriceItems",
                ],
              },
            },
            delete: userManager.checkRole(["kt"]) && {
              confirmDisplayField: "sku",
            },
          },
        },
      };
      if (self.item.properties) {
        const extraDisplayFields = {};
        const extraInputFields = [];
        for (const property of self.item.properties) {
          const key = `property_${property.key}`;
          extraDisplayFields[key] = {
            text: property.label,
            options: {
              subProp: `propertyValues.${property.key}`,
              transform(value = "") {
                return `<span class="font-weight-bold red--text">${value}</span>`;
              },
            },
          };
          const defaultInput: any = {
            attrs: {
              label: property.label || property.key,
            },
            ext: {
              formProperty: `propertyValues.${property.key}`,
            },
          };
          if (property.type === "select") {
            defaultInput.type = "select";
            defaultInput.attrs.items = property.selections;
          }
          options.ext.default.defaultInputs[key] = defaultInput;
          extraInputFields.push(key);
        }
        options.content.displayFields = Object.assign(
          extraDisplayFields,
          options.content.displayFields
        );
        if (options.ext.default.insertForm) {
          options.ext.default.insertForm.content.fieldNames.unshift(
            ...extraInputFields
          );
        }
        if (options.ext.default.editForm) {
          options.ext.default.editForm.content.fieldNames.unshift(
            ...extraInputFields
          );
        }
      }
      return options;
    },
  },
  async created() {
    this.country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(this.country);
  },
});
